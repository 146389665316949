import React,{ useState, useEffect } from 'react';
import { Link } from "react-router-dom";

var checkImage = function(url, setImageExist) {
    if(!document) return;
    if(!document.createElement) return;
    let s = document.createElement("IMG");
    s.src = url
    s.onerror = function(){
        setImageExist(false);
    }
    s.onload = function(){
        setImageExist(true);
    }
}

export default function CategoryDetail({ items, title }) {
    //console.log({ items });
    return (<>
        {
            items.map((i) => {
                return <CategoryVignette key={i.name} item={i}></CategoryVignette>
            })
        }
        <div aria-hidden="true" className="vignette-link"></div>
        <div aria-hidden="true" className="vignette-link"></div>
        <div aria-hidden="true" className="vignette-link"></div>
        <div aria-hidden="true" className="title-vignette-container">{title}</div>

    </>)
}


function CategoryVignette({ item }) {

    if(item.hide === true || item.hide === "true") return null;
    if((item.loggedin === true || item.loggedin === "true") && !window?.DENTAL_APP_USER?.id) return null;

    let [imageExist, setImageExist] = useState(false);

    const thumbnailHref = item.thumbnailHref;
    useEffect(() => {
        checkImage(thumbnailHref, setImageExist)
    }, [thumbnailHref]);

    return (<div className="vignette-link">
        <Link style={ imageExist ? { backgroundImage: `url(${item.thumbnailHref})` } : null } to={item.href}>
            <span className="title">{item.title}</span>
        </Link>
    </div>)

}